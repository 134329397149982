.side-bar{
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 1050px;
    row-gap:25px;
}
.side {
    /* border: 1px solid red; */
    background: #fff;
    border-radius: 10px;
    padding: 25px 0px 0px 25px;
    box-shadow: 1px 2px 10px 0.7px rgb(247, 247, 247);
    height:500px;
    width: 100%;
}
.side ul{
    list-style: none;
    text-decoration: none;
    text-align: left;
}
.side ul li{
    margin-bottom: 20px;
    cursor: pointer;
  
}
.side ul li span{
    text-align: left;
    text-align: start;
}
.side ul li i{
   font-size: 20px;
   width: 15px;
   color: var(--font-2);
}
.side ul li span{
    margin-left: 35px;
    font-weight: normal;
    color: var(--font-2);
}


.side-btm{
    background: #fff;
    border-radius: 10px;
    padding: 25px 0px 0px 25px;
    box-shadow: 1px 2px 10px 0.7px rgb(247, 247, 247);
    height:500px;
    width: 100%;
    position: relative;
}
.side-btm-m{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.side-btm-m img{
    height:500px;
    width: 100%;
    object-fit: cover;
    bottom: 0;
    border-radius: 10px;
}

@media screen and (max-width:540px){
    .side-bar{
        display: none;
    }
}