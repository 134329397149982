
.b-img-slider{
    width:400px;
    height: 500px;
    /* border: 1px solid rgb(211, 66, 211); */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.b-img-slider img{
        /* border: 1px solid rgb(32, 85, 218); */
        width:75%;
        object-fit: cover;
}

.swiper-pagination-bullet{
    width:30px !important;
    height: 30px !important;
}

.b-img-slider .swiper-pagination-bullet:nth-child(1)
{
    background: rgb(93, 103, 151) !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet:nth-child(2)
{
    background: rgb(48, 91, 60) !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet:nth-child(3)
{
    background-color: rgb(69, 69, 69) !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet:nth-child(4)
{
    background: rgb(35, 35, 41)!important;
    opacity: 1 !important;
}
.swiper-pagination-bullet-active {
    background-color: rgb(255, 85, 85) !important;
    border: 3px solid rgb(42, 42, 42);
} 



@media screen and (max-width:540px) {
.b-img-slider{
    width:200px;
    height: 300px;
}
.swiper-pagination-bullet{
    width:15px !important;
    height: 15px !important;
}
}