.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    /* border: 1px solid red; */
}
.nav-right{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.mail input{
    height: 35px;
    width: 200px;
    border-radius: 25px;
    border: none;
    outline: none;
    padding-left: 15px;
}
.mail button{
    border-radius: 25px;
    background: rgb(154, 116, 231);
    border: none;
    outline: none;
    padding: 10px 15px 10px 15px;
    color: #fff;
    cursor: pointer;
}
.account,.cart{
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
    color: var(--font-color);
}
.nav img{
    width:25px;
}


@media screen and (max-width:540px) {
    .nav-right{
        display: none;
    }
}