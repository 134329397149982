.search{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.s-left h2{
    color: var(--font-2);
    font-size:30px;
}
.s-right{
    display: flex;
    align-items: center;
    gap: 25px;
}
.s-right input{
    height: 40px;
    width: 300px;
    border: none;
    outline: none;
    border-radius: 25px;
    background: rgb(218, 204, 250);
    padding-left: 15px;
    color: white;
    font-size: 16px;
}
.s-right img{
    width: 35px;
}

@media screen and (max-width:540px){
    .search{
        display: flex;
        flex-direction: column;
    }
    .s-left h2{
        font-size:20px;
    }
    .s-right input{
        height: 35px;
        width: 100%;
    }
    .s-right{
        display: flex;
        align-items: center;
        gap: 10px;
    }
}